<template>
  <Layout>
    <div class="loginBox pa-6">
      <v-row align="center" justify="center">
        <v-col>
          <ValidationObserver ref="observer">
            <form @submit.prevent="tryToLogIn">
              <h3 class="text-h5 font-weight-medium">Recupero Credenziali</h3>
              <p class="subtitle-1">
                Inserisci il tuo username o la tua mail per procedere all'invio
                di una nuova password
              </p>
              <ValidationProvider
                v-slot="{ errors }"
                name="Username"
                rules="required"
              >
                <v-text-field
                  v-model.trim="usernameOrEmail"
                  :error-messages="errors"
                  label="Username o Email"
                  type="text"
                  filled
                  rounded
                  required
                />
              </ValidationProvider>

              <v-alert v-if="result" :color="result_color" dark>
                {{ result }}
              </v-alert>
              <v-btn
                block
                large
                depressed
                rounded
                dark
                flat
                :color="visualSettings.accent"
                class="mt-3"
                :loading="loading"
                @click.native="recovery"
              >
                Conferma la richiesta
              </v-btn>

              <v-btn block text small class="mt-5" @click.native="goToLogin">
                torna al login
              </v-btn>

              <v-spacer class="mt-12" />
            </form>
          </ValidationObserver>
        </v-col>
      </v-row>
    </div>
  </Layout>
</template>

<script>
import Layout from '@layouts/auth.vue'
import appConfig from '@src/app.config'
import { client } from '@api/client'
import utils from '@mixins/utils.js'

export default {
  page: {
    title: 'Log in',
    meta: [{ name: 'description', content: `Log in to ${appConfig.title}` }],
  },
  components: { Layout },
  mixins: [utils],
  data: () => ({
    usernameOrEmail: '',
    result: null,
    loading: false,
    result_color: null,
  }),
  computed: {
    imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 180
        case 'sm':
          return 240
        default:
          return 360
      }
    },
  },
  methods: {
    // Try to log the user in with the username
    // and password they provided.
    async recovery() {
      try {
        await client.post('auth/recovery', {
          usernameOrEmail: this.usernameOrEmail,
        })
        this.result =
          'Operazione terminata con successo, abbiamo inviato una mail con una nuova password temporanea'
        this.result_color = 'light-green'
      } catch (error) {
        this.result = 'Errore recupero dati'
        this.result_color = 'red accent-2'
      }
    },
    goToLogin() {
      this.$router.push({ name: 'login' })
    },
  },
}
</script>

<style>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.loginBox {
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 16px;
}
</style>
